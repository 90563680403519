import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { fetchFrasesZeFibra } from "../api/api";
import "./MascoteFibra.css";

const MascoteFibra = ({ informacoesFluxo, pagina }) => {
    const [expandido, setExpandido] = useState(false);
    const [copiado, setCopiado] = useState(false);
    const [frasesZeFibra, setFrasesZeFibra] = useState([]);
    const [fraseAtual, setFraseAtual] = useState("");

    useEffect(() => {
        const obterFrases = async () => {
            const frases = await fetchFrasesZeFibra();
            setFrasesZeFibra(frases);
        };
        obterFrases();
    }, []);

    useEffect(() => {
        if (frasesZeFibra.length === 0) {
            setFraseAtual("Bem vindo a Triagem de problemas!");
        } else if (!expandido && informacoesFluxo.length === 0) {
            const fraseAleatoria = frasesZeFibra[Math.floor(Math.random() * frasesZeFibra.length)].texto;
            setFraseAtual(fraseAleatoria);
        }
    }, [expandido, informacoesFluxo, frasesZeFibra, pagina]);

    const handleToggleExpand = () => {
        if (informacoesFluxo.length > 0) {
            setExpandido(!expandido);
        }
    };

    const handleCopyToClipboard = () => {
        const textToCopy = informacoesFluxo.join("\n");
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopiado(true);
            setTimeout(() => setCopiado(false), 1200);
        });
    };

    return (
        <div className="mascote-fibra-container">
            <div className="mascote-fibra-image">
                <img src="/static/images/mascote-olhando-cima.png" alt="Mascote Zé Fibra" />
            </div>

            {expandido ? (
                <div
                    className="mascote-fibra-balao expandido"
                    onClick={handleToggleExpand}
                >
                    <ul>
                        {informacoesFluxo.map((info, index) => (
                            <li key={index}>{info}</li>
                        ))}
                    </ul>

                    <div className="copy-icon" onClick={(e) => {
                        e.stopPropagation();
                        handleCopyToClipboard();
                    }}>
                        <FontAwesomeIcon icon={copiado ? faCheck : faCopy} />
                    </div>
                </div>
            ) : (
                <div className="mascote-fibra-balao" onClick={handleToggleExpand}>
                    {informacoesFluxo.length === 0 ? (
                        <p>{fraseAtual}</p>
                    ) : (
                        <p>Clique aqui para exibir ou ocultar os procedimentos já realizados</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default MascoteFibra;
