import React, { useEffect, useState } from "react";
import { fetchChangelog } from "../api/api";
import "./ChangelogPage.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { addTargetBlankToLinks } from "../utils/utils";

const ChangelogPage = () => {
    const [changelog, setChangelog] = useState();

    useEffect(() => {
        const getChangelog = async () => {
            const data = await fetchChangelog();
            setChangelog({
                ...data,
                copyright_html: addTargetBlankToLinks(data.copyright_html),
            });
        };
        getChangelog();
    }, []);

    if (!changelog) {
        return <div>Loading...</div>;
    } else {
        console.log(changelog)
    }

    return (
        <div className="changelog-container">
            <Navbar titulo="Changelog" isHomePage={true} />
            <ul className="timeline">
            {changelog.entries.map(entry => (
                <li key={entry.titulo} className="changelog-entry" data-date={new Date(entry.criado_em + 'T00:00:00Z').toLocaleDateString("pt-BR", { timeZone: "UTC" })}>
                    <h1>{entry.titulo}</h1>
                    <div dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(entry.conteudo_html) }} />
                </li>
            ))}
            </ul>
            <Footer titulo={changelog.titulo} copyright={changelog.copyright_html} />
        </div>
    );
};

export default ChangelogPage;
