import React from "react";
import "./ConfirmaHomePopup.css";

const ConfirmaHomePopup = ({ handleTriagemClick, handleCancel }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <p>Você possui procedimentos em andamento. Deseja ir para a página inicial e perder essas informações?</p>
                <button onClick={handleTriagemClick}>Sim, ir para a página inicial</button>
                <button onClick={handleCancel}>Cancelar</button>
            </div>
        </div>
    );
};

export default ConfirmaHomePopup;
