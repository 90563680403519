/**
 * Gera um número inteiro aleatório entre o intervalo especificado, inclusive.
 * @param {number} min - O número mínimo no intervalo.
 * @param {number} max - O número máximo no intervalo.
 * @returns {number} - Um número inteiro aleatório entre min e max.
 */
export const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const addTargetBlankToLinks = (htmlContent) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    const links = div.querySelectorAll("a");
    links.forEach(link => link.setAttribute("target", "_blank"));
    return div.innerHTML;
};
