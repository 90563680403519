import React from "react";

const BotaoFluxo = ({ botao, onClick }) => {
    const buttonStyles = botao.imagem_de_fundo
        ? {
            backgroundImage: `url(${botao.imagem_de_fundo})`,
          }
        : { backgroundColor: botao.cor_de_fundo };

    return (
        <button
            className="fluxo-button"
            style={buttonStyles}
            onClick={() => onClick(botao)}
        >
            <span dangerouslySetInnerHTML={{ __html: botao.texto_html }} />
        </button>
    );
};

export default BotaoFluxo;
