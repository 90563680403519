import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { getRandomInt, addTargetBlankToLinks } from "../utils/utils";
import "./PaginaContent.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchChangelog } from "../api/api";
import MascoteFibra from "./MascoteFibra";
import PaginaComMidia from "./PaginaComMidia";
import PaginaTexto from "./PaginaTexto";

const PaginaContent = ({ pagina, onButtonClick, loadFluxo, isHomePage, starryCanvasRef }) => {
    const nodeRef = useRef(null);
    const navigate = useNavigate();
    const [fadeOutContent, setFadeOutContent] = useState(false);
    const [showNewContent, setShowNewContent] = useState(isHomePage);
    const [changelog, setChangelog] = useState({});
    const location = useLocation();
    const informacoesFluxoInicial = location.state?.informacoesFluxo || [];
    const [informacoesFluxo, setInformacoesFluxo] = useState(informacoesFluxoInicial);
    const modifiedPagina = {
        ...pagina,
        midia: pagina.midia ? {
            ...pagina.midia,
            procedimento_html: addTargetBlankToLinks(pagina.midia.procedimento_html),
            legenda_html:  addTargetBlankToLinks(pagina.midia.legenda_html),
            dica_html:  addTargetBlankToLinks(pagina.midia.dica_html),
        } : null,
        conteudo_html: pagina.conteudo_html ? addTargetBlankToLinks(pagina.conteudo_html) : null
    };

    const modifiedChangelog = {
        ...changelog,
        copyright_html: addTargetBlankToLinks(changelog.copyright_html)
    };

    const renderPaginaContent = () => {
        switch (pagina.tipo_pagina) {
            case "pagina_com_midia":
                return <PaginaComMidia pagina={modifiedPagina} triggerStarAnimation={triggerStarAnimation} loadFluxo={loadFluxo} />;
            case "pagina_texto":
                return <PaginaTexto pagina={modifiedPagina} />;
            default:
                return <div>Tipo de página desconhecido</div>;
        }
    };

    const triggerStarAnimation = (botao) => {
        // Fase 1: Esconder o título e os cards da página atual
        setFadeOutContent(true);
        document.body.classList.add('no-scroll');

        // Após o fade out do conteúdo atual, iniciamos a animação das estrelas
        const randomTx = getRandomInt(-20, 20);
        const randomTy = getRandomInt(-20, 20);
        let animationInterval = setInterval(() => {
            if (starryCanvasRef.current) {
                starryCanvasRef.current.changeVelocity({ tx: randomTx, ty: randomTy });
            }
        }, 100); // Atualizar a animação das estrelas a cada 100ms

        const callbackInterval = setInterval(() => {
            const novaInformacao = botao.informacao_fluxo;
            let novoState;

            if (novaInformacao && informacoesFluxo.indexOf(novaInformacao) == -1) {
                const botaoInformacaoNaPagina = pagina.cards[0].botoes.find(botao => informacoesFluxo.indexOf(botao.informacao_fluxo) != -1);

                if (botaoInformacaoNaPagina) {
                    const indexToRemove = informacoesFluxo.indexOf(botaoInformacaoNaPagina.informacao_fluxo);

                    const result = informacoesFluxo.filter((_, index) => index !== indexToRemove)
                    setInformacoesFluxo([...result, novaInformacao]);
                    novoState = { state: { informacoesFluxo: [result, novaInformacao] } };
                } else {
                    setInformacoesFluxo([...informacoesFluxo, novaInformacao]);
                    novoState = { state: { informacoesFluxo: [...informacoesFluxo, novaInformacao] } };
                }
            }

            if (pagina.fluxo_slug != botao.link_para_fluxo_slug) {
                loadFluxo(botao.link_para_fluxo_slug, botao.link_para_pagina_slug).then(() => {
                    navigate(`/fluxo/${botao.link_para_fluxo_slug}/${botao.link_para_pagina_slug}`, novoState);
                    stopAnimationAndShowContent();
                }).catch((error) => {
                    console.error("Erro ao carregar o fluxo: ", error);
                });
            } else if (onButtonClick) {
                // Se onButtonClick estiver definido, estamos na PaginaDetalhePage
                onButtonClick(botao.link_para_pagina_slug, novoState);
                stopAnimationAndShowContent();
            }
        }, 500);

        // Função para parar a animação das estrelas e mostrar o novo conteúdo
        const stopAnimationAndShowContent = () => {
            clearInterval(animationInterval); // Para a animação das estrelas
            clearInterval(callbackInterval);
            setFadeOutContent(false); // Mostrar o novo conteúdo
            setShowNewContent(true); // Mostrar a nova página
            document.body.classList.remove('no-scroll');
        };
    };

    useEffect(() => {
        if (!isHomePage) {
            setShowNewContent(true);
        }
    }, [isHomePage]);

    useEffect(() => {
        const getChangelog = async () => {
            const data = await fetchChangelog();
            setChangelog(data);
        };
        getChangelog();
    }, []);

    return (
        <div className="page-content">
            <Navbar titulo={pagina.titulo_html} isHomePage={isHomePage} informacoesFluxo={informacoesFluxo} idPagina={pagina.id_pagina} />
            <CSSTransition in={!fadeOutContent && showNewContent} timeout={5000} classNames="fade" nodeRef={nodeRef} unmountOnExit>
                <div ref={nodeRef}>
                    {renderPaginaContent()}
                    <Footer titulo={modifiedChangelog.titulo} copyright={modifiedChangelog.copyright_html} />
                </div>
            </CSSTransition>
            <MascoteFibra informacoesFluxo={informacoesFluxo} pagina={pagina} />
        </div>
    );
};

export default PaginaContent;
