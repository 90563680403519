import React from "react";
import "./PaginaTexto.css";

const PaginaTexto = ({ pagina }) => {
    return (
        <div className="pagina-texto-layout">
            <div
                className="pagina-texto__emoji"
                dangerouslySetInnerHTML={{ __html: pagina.emoji_html }}
            />
            <div
                className="pagina-texto__conteudo"
                dangerouslySetInnerHTML={{ __html: pagina.conteudo_html }}
            />
        </div>
    );
};

export default PaginaTexto;
