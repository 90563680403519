import React from "react";
import "./Footer.css";

const Footer = ({ titulo, copyright }) => {
    return (
        <footer className="footer">
            <span className="footer-left"><a className="footer-left__link" href="/changelog" target="_blank" rel="noopener noreferrer">{ titulo }</a></span>
            <span className="footer-center" dangerouslySetInnerHTML={{ __html: copyright }}/>
        </footer>
    );
};

export default Footer;
