import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import ConfirmaHomePopup from "./ConfirmaHomePopup";

const Navbar = ({ titulo, isHomePage, informacoesFluxo, idPagina }) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);

    const handleBackClick = () => {
        if (!isHomePage) {
            navigate(-1);
        }
    };

    const handleTriagemClick = () => {
        navigate("/");
    };

    const handleShouldShowPopup = () => {
        if (informacoesFluxo && informacoesFluxo.length > 0) {
            setShowPopup(true);
        } else {
            handleTriagemClick();
        }
    };

    const handleCancel = () => {
        setShowPopup(false);
    }

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <div className={`nav-icon ${isHomePage ? "disabled" : ""}`} onClick={handleBackClick}><img src="/static/images/voltar-icon.png" alt="voltar" /></div>
                <div className="nav-icon"><img src="/static/images/home-icon.png" alt="home" /></div>
                <div className="nav-icon" onClick={handleShouldShowPopup}><img src="/static/images/tres-barras-icon.png" alt="tres barras" /></div>
            </div>

            <div className="navbar-center">
                <span dangerouslySetInnerHTML={{ __html: titulo }} className="page-title"></span>
                <img src="/static/images/portal-mv-telecom-header.png" alt="Logo" className="logo" />
            </div>

            <div className="navbar-right">
                <div className="nav-icon"><span>{ idPagina }</span></div>
                <div className="nav-icon"><img src="/static/images/config-icon.png" alt="configurações" /></div>
                <div className="nav-icon"><img src="/static/images/user-icon.png" alt="perfil" /></div>
            </div>

            {showPopup && (<ConfirmaHomePopup handleTriagemClick={handleTriagemClick} handleCancel={handleCancel} />)}
        </nav>
    )
};

export default Navbar;
