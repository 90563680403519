// PaginaComMidia.js
import React from "react";
import Card from "./Card";
import Dica from "./Dica";

const PaginaComMidia = ({ pagina, triggerStarAnimation, loadFluxo }) => {
    const midia = pagina.midia && pagina.midia.arquivo ? pagina.midia : null;

    return (
        <div>
        { midia ? (
            <div className="pagina-midia-layout">
                <div className="midia-container">
                    {midia.tipo === 'imagem' && (
                        <div className="midia-image">
                            <img src={midia.arquivo} alt={midia.legenda || 'Imagem'} />
                            {midia.legenda && <div className="midia-legenda" dangerouslySetInnerHTML={{ __html: midia.legenda_html }}/>}
                        </div>
                    )}
                    {midia.tipo === 'video' && (
                        <div className="midia-video">
                            <video key={midia.arquivo} autoPlay controls disablePictureInPicture  controlsList="nodownload noplaybackrate">
                                <source src={midia.arquivo} type="video/mp4"></source>
                            </video>
                            {midia.legenda && <div className="midia-legenda" dangerouslySetInnerHTML={{ __html: midia.legenda_html }}/>}
                        </div>
                    )}
                </div>
                <div className="informacoes-container">
                    <div className="pagina-procedimento">
                        <div className="procedimento-texto" dangerouslySetInnerHTML={{ __html: midia.procedimento_html }} />
                    </div>

                    {midia.dica_html && (
                        <Dica dica={midia.dica_html} piscando={midia.dica_piscando} />
                    )}

                    <div className="pagina-card-container">
                        {pagina.cards.map((card) => (
                            <Card
                                key={card.id}
                                titulo={card.titulo_html}
                                corDeFundo={card.cor_de_fundo}
                                botoes={card.botoes}
                                triggerStarAnimation={triggerStarAnimation}
                                isMediaPage={true}
                            />
                        ))}
                    </div>
                </div>
            </div>
        ) : (
            <div>
                {pagina.cards.map((card) => (
                    <Card
                        key={card.id}
                        titulo={card.titulo_html}
                        corDeFundo={card.cor_de_fundo}
                        botoes={card.botoes}
                        loadFluxo={loadFluxo}
                        triggerStarAnimation={triggerStarAnimation}
                        isMediaPage={false}
                    />
                ))}
            </div>
        )}
        </div>
    );
};

export default PaginaComMidia;
